<div class="creator-search-container">
  <h1 class="m-0 mb-4">{{ showMatchingCreators ? 'Suchergebnisse' : 'Bitte wählen Sie das gewünschte Recht und geben Sie den Namen ein' }}:</h1>
  <form #form="ngForm" [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="row">
      <div class="col-12 col-md-5">
        <mat-form-field class="w-100">
          <mat-label>Art des Rechts</mat-label>
          <mat-select formControlName="rightType" required>
            <mat-option *ngFor="let item of rightTypes" [value]="item.key">{{ item.value | rightType }}</mat-option>
          </mat-select>
          <mat-error>Bitte angeben</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5">
        <mat-form-field class="w-100">
          <mat-label>Name oder Künstlername</mat-label>
          <input type="text" matInput formControlName="name" name="name" required>
          <mat-error>Bitte angeben</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 text-center text-md-left mt-3">
        <button mat-raised-button color="primary" [disabled]="!isValid()" class="w-100">absenden</button>
      </div>
    </div>
  </form>

  <ng-container *ngIf="showMatchingCreators">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="w-75">Name, Vorname / Pseudonym</th>
        <td mat-cell *matCellDef="let creator">{{ displayName(creator) }}</td>
      </ng-container>

      <ng-container matColumnDef="countries">
        <th mat-header-cell *matHeaderCellDef class="w-25">Länder</th>
        <td mat-cell *matCellDef="let creator">{{ creator.countries == null ? '-' : creator.countries.join(' ') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dismiss">
        <th mat-header-cell *matHeaderCellDef class="d-flex justify-content-end">
          <button mat-icon-button (click)="dismissCreators()"><i class="material-icons">clear</i></button>
        </th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
  </ng-container>

  <div class="result-explanation-area">
    <h2>Suchergebnisse</h2>
    <p>
      Bei Namen, die mit einem nachgestellten Stern gekennzeichnet sind, handelt es sich um Pseudonyme,
      zu denen der VG Bild-Kunst ein bürgerlicher Name (Patronym) bekannt ist.<br />
    </p>
    <p><b>Wird kein Name angezeigt, vertreten wir das ausgewählte Recht für den gesuchten Künstler
      oder die gesuchte Künstlerin nicht. Prüfen Sie bitte einmal, ob die oben genannten Hinweise zu
      den Schreibweisen weiterhelfen können.</b></p>
    <p>
      <b>Klicken Sie auf das ✖ am rechten oberen Rand der Tabelle um die Suche zurückzusetzen.</b>
    </p>
  </div>

  <div class="download-area mt-5">
    <p>
      Wenn Sie für einen umfangreichen Abgleich eine Gesamtliste der je nach Recht vertretenen
      Urheber*innen benötigen, finden Sie hier einen <b>Download als CSV-Datei</b>
    </p>
    <div class="row download-buttons mt-5">
      <div class="col-12 col-md-4 mb-2">
        <a [href]="downloadEndpoint + '/1'" target="_self" mat-stroked-button color="primary" class="w-100">
          Onlinerechte <i class="material-icons">get_app</i>
        </a>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <a [href]="downloadEndpoint + '/2'" target="_self" mat-stroked-button color="primary" class="w-100">
          Reproduktionsrechte <i class="material-icons">get_app</i>
        </a>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <a [href]="downloadEndpoint + '/3'" target="_self" mat-stroked-button color="primary" class="w-100">
          Folgerechte <i class="material-icons">get_app</i>
        </a>
      </div>
    </div>
  </div>
</div>
<mat-progress-bar *ngIf="showProgressBar" @progressBarFade mode="indeterminate" color="primary"></mat-progress-bar>
