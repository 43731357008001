import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/main/src/environments/environment';
import { Observable } from 'rxjs';
import { CreatorViewModel } from './types/creator-view-model';
import { isNullOrWhitespace } from '../shared/functions';

@Injectable({
    providedIn: 'root'
})
export class CreatorSearchService {    
  static endpoint: string = environment.serviceUrl + "/search";

  constructor(private httpClient: HttpClient) { }

  public getCreators(id: string, name: string): Observable<CreatorViewModel[]> {
    return this.httpClient.post<CreatorViewModel[]>(CreatorSearchService.endpoint, { options: id, name: name});
  }
}
